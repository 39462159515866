.app__portfolio {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__portfolio-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__portfolio-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: var(--primary-color);
        color: #000;
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.5rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
            font-size: 0.9rem;
            padding: 1rem 2rem;
            border-radius:  0.85rem;            
        }

    }
    .item-active {
        background-color: var(--secondary-color);
        color: #fff;
    }

}

.app__portfolio-projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    .app__portfolio-item {
        width: 340px;
        flex-direction: column;
        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: var(--primary-color);
        color: black;
        transition: all 0.3s ease;
        &:hover {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        }
      
        @media screen and (min-width: 2000px) {
            padding: 1.25rem;
            border-radius: 0.75rem;
            
        }
        @media screen and (max-width: 500px) {
            width: 80%;
            margin: 1rem;
            
        }

    }
    .app__portfolio-img {
        width: 100%;
        height: 230px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            object-fit: cover;
        }

        @media screen and (min-width: 2000px) {
            height: 300px;
            
        }
    }
}

.app__portfolio-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;

    .portfolio-link {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        a {
            width: 100%;
            height: 100%;
            svg {
                width: 50%;
                height: 50%;
                color: var(--white-color);
            }
        }
       


    }

}

.app__portfolio-content {
    padding: 0.5rem;
    width: 100%;
    min-height: 105px;
    position: relative;
    flex-direction: column;
    
    h4 {
        margin-top: 1rem;
        line-height: 1.5;
    }

    .app__portfolio-tag {
        position: absolute;

        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: #fff;
        top: -25px;
    }
}

.app__portfolio-content-links {
    display: flex;
    flex-direction: row;
    @media (any-hover: hover) {
        display: none;
    }
    div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // background-color: rgba(0, 0, 0, 0.5);
        // color: black;
        background-color: var(--secondary-color);

        margin: 0.5rem 1.5rem;
        font-family: var(--font-base);
        font-weight: 800;
        transition: all 0.3s ease;
        svg {
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }


    }

}