.app__about {
    flex: 1;
    width: 100%;
    flex-direction: column;

}

.app__about-link {
    margin-top: 2rem;
    background: var(--white-color);
    padding: 1rem 2.5rem;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;


    p {
        color: #000;
        font-weight: 500;
        font-size: larger;
        transition: all 0.3s ease;

        @media screen and (min-width: 2000px) {
            font-size: large;
            
        }
    }

    &:hover {
        background: var(--secondary-color);
        transition: all 0.3s ease;

        
        p {
            color: #fff;
            transition: all 0.3s ease;

        }
    }
}